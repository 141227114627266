// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "font-awesome/scss/font-awesome";

// Datatables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';
@import 'datatables.net-responsive-bs4';

@import "~fastselect/dist/fastselect";

@import "~bootstrap4-tagsinput-douglasanpa/tagsinput.css";

@import "~bootstrap-datetime-picker/css/bootstrap-datetimepicker.css";

//theme css
@import "../css/admin.css";
